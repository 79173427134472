/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CheckIn, Reservation } from '@/types';
import { settings } from '@/variables';
import { util } from '@/functions';
import { authToken, handleAuthorization } from '@/_helpers/auth-header';

const header= {headers: {Authorization: `bearer ${authToken()}`}, method: "GET"};


export const roomService = {
    getAllReservations,
    insertReservation,
    deleteReservation,
    replaceReservation,
    extendReservation,
    addRoom,
    updateReservation,
    checkIn,
    getCheckIns,
    getTodayCheckIns,
    getOpenCheckIns,
    updateCheckin,
    deleteCheckin,
    getAll,
    getFloors,
    changeRoom,
    deleteRoom,
    getFloor,
    getRoom,
    getUnconfirmed,
    confirmReservations,
    moveReservations,
    deleteReservations,
    getHolidays,
};

function getAllReservations(today: Date, numDays: number) {
    const url= `${util.apiUrl()}/room/reservation/${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getHolidays() {
    const url= `${util.apiUrl()}/holidays`;
    return fetch(url, header).then(handleAuthorization);
}
function getTodayCheckIns() {
    return getCheckIns(new Date(), 45);
}
function getCheckIns(today: Date, numDays: number) {
    const url= `${util.apiUrl()}/checkin`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}

function getOpenCheckIns(today: Date, numDays: number) {
    const url= `${util.apiUrl()}/checkin/open`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getFloors() {
    const url= `${util.apiUrl()}/floor`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getFloor(floorNr: string) {
    const url= `${util.apiUrl()}/floor/${floorNr}`; // /${today}`;
    return fetch(url, header).then(handleAuthorization);
}
function getRoom(rKey: string) {
    const url= `${util.apiUrl()}/room/${rKey}`;
    return fetch(url, header).then(handleAuthorization);
}
function getUnconfirmed() {
    const url= `${util.apiUrl()}/client/confirmations`;
    return fetch(url, header).then(handleAuthorization);
}
function confirmReservations() {
    const url= `${settings.printApi}/Notifications?action=confirm`;
    return fetch(url, header).then((result: Response) => {
        console.log("Response: " + JSON.stringify(result));
    });
}
function updateCheckin(data: CheckIn) {
    const url= `${util.apiUrl()}/checkin/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleUpdateCheckin);
}

function insertReservation(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/reservation`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}


function getAll() {
    const url= `${util.apiUrl()}/room`;
    return fetch(url, header).then(handleAuthorization);
}


function updateReservation(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/reservation/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function extendReservation(data: Record<string, unknown>, extendDate: string) {
    const extDat= extendDate.substring(0, 10);
    const url= `${util.apiUrl()}/reservation/${extDat}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    data.resDate= (data.resDate as string).substring(0, 10);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);

}

function replaceReservation(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/reservation/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function deleteReservations(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/reservation/deleteReservations`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function moveReservations(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/reservation/moveToRoom`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function addRoom(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/room`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}
function changeRoom(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/room/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}
function deleteRoom(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/room/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'DELETE',
        headers: headerObj,
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function checkIn(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/checkin`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleCheckIn);
}

function deleteReservation(data: Reservation) {
    const url= `${util.apiUrl()}/reservation/${data.key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'DELETE',
        headers: headerObj,
        // body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}


function deleteCheckin(data: CheckIn) {
    const url= `${util.apiUrl()}/checkin/${data._key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'DELETE',
        headers: headerObj,
        // body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleInsertReservation);
}

function handleInsertReservation(response: any) {
    return response.text().then((text: any) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}

function handleCheckIn(response: any) {
    return response.text().then((text: any) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}
function handleUpdateCheckin(response: any) {
    return response.text().then((text: any) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}
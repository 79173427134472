/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { util } from '@/functions';
import { authToken } from '@/_helpers/auth-header';

export const permissionService = {
    getPermissions,
};

function getPermissions() {
    const url= `${util.apiUrl()}/permission`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'GET',
        headers: headerObj,
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handlePermissions);
}
function handlePermissions(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        console.debug("handling response from server: " + JSON.stringify(data));
        return Promise.resolve(data);
    });
}





























































































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { clientService, roomService, fileService} from '../_services';
import DropableCell from '@/components/DropableCell.vue';
import { settings } from '@/variables';
import EventBus from '@/variables'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { Reservation, RoomElement, Floor, IRoom, Client } from '@/types';
// import { useDateFormat, useNow } from '@vueuse/core'

Vue.use(BootstrapVueIcons);

export default Vue.extend({
  name: "App",
  data() {
    return {
      hasUnconfirmed: false,
      transProps: {
        name: 'flip-list'
      },
      fieldsStart: [
        /* { key: "floor", label: "Stockwerk", stickyColumn: true, }, */
        { key: "name", label: "Zimmer", stickyColumn: true, } ,
      ],
      fields: [ { key: "floor", label: "Stockwerk", stickyColumn: true,  } ],
      holidays: [
        {date: "2024-01-01"},
        {date: "2024-01-06"},
        {date: "2024-04-01"},
        {date: "2024-05-01"},
        {date: "2024-05-09"},
        {date: "2024-05-20"},
        {date: "2024-05-30"},
        {date: "2021-11-01"},
      ],
      clientFields: [
        { key: "fullname", label: "Drag-me" },
        { key: "artistName", label: "Künstlername", sortable: "true"},
        { key: "firstName", label: "Vorname", sortable: "true", },
        { key: "lastName", label: "Nachname", sortable: "true", },
        { key: "status", label: "Status", sortable: "true", },
      ],
      showEditRoom: false,
      floors: [] as any[],
      selectedRoomElem: {} as RoomElement,
      nameMonth1: "",
      searchString: "",
      numMonth1: 0,
      nameMonth2: "",
      numMonth2: 0,
      nameMonth3: "",
      numMonth3: 0,
      hideMonth3: false,
      imageSource: "",
      reservationOnWaitingList: false,
      startReservation: {} as Reservation,
      endReservation: {} as Reservation,
      items: [] as any[],
      newRoom: {
        floor: "0",
        number: 1,
        name: 'Zimmer 1',
        priceWorkdayWork: 75,
        priceWorkdayNight: 55,
        priceWeekendWork: 30,
        priceWeekendNight: 25,
      },
      infoModal: {
          id: 'reservationInfo',
          title: '',
          content: '',
          data: {},
          isNew: false
      },
      clients: [],
      selectedReservation: {} as Reservation,
      today: new Date(),
      monthNames: settings.months,
      eventHub: EventBus,
      selectedRoom: '',
      preselectedReservation: {} as Reservation,
    };
  },
  created():void {
    roomService.getHolidays().then(list => {
      this.holidays = list;
      this.reCreate();
    })
  },
  mounted():void {
    console.log("mounted");
    this.eventHub.$on('reservationDelete', (reservation1: Reservation, reservation2: Reservation) => {
      console.log("on reservationDelete " + JSON.stringify(reservation1) + JSON.stringify(reservation2));
      this.startReservation= reservation1;
      this.endReservation= reservation2;
      this.$root.$emit('bv::show::modal', "reservationDelete")
    });
    this.eventHub.$on('reservationMove', (reservation1: Reservation, reservation2: Reservation) => {
      console.log("on reservationMove " + JSON.stringify(reservation1) + JSON.stringify(reservation2));
      this.startReservation= reservation1;
      this.endReservation= reservation2;
      this.$root.$emit('bv::show::modal', "reservationMove")
    });
    this.eventHub.$on('reservationInfo', (reservation: Reservation) => {
      this.selectedReservation= reservation;
      this.reservationOnWaitingList = this.selectedReservation.waitingList=='y';
      this.$root.$emit('bv::show::modal', "reservationInfo")
      this.loadClientImage();
      this.items.forEach((roomElem: RoomElement) => {
        if (roomElem._key==reservation.room) {
          this.selectedRoom= roomElem.name;
        } 
      });
    });
    this.eventHub.$on("showChooseCustomerDialog", (reservation: Reservation) => {
      console.log("show Dialog, recieved reservation: " + JSON.stringify(reservation));
      this.preselectedReservation= reservation;
      this.$root.$emit('bv::show::modal', "chooseCustomer")
    })
  },
  methods: {
    moveReservations(roomId: number) {
      console.log("moving reservations server side")
      let selectedReservations= this.getSelectedReservations();
      console.log("this are the selected reservations: " + JSON.stringify(selectedReservations));
      let movingReservations = {
        toRoom: roomId,
        selectedReservations,
      }
      roomService.moveReservations(movingReservations).then((e) => {
        console.log("moved reservations");
        this.$router.go(0);
        this.reCreate();
      }).catch((e) => {
        console.log("Error occured " + JSON.stringify(e))
      })
      this.$root.$emit('bv::hide::modal', "reservationInfo");
    },
    deleteSelectedReservations() {
      console.log("moving reservations server side")
      let selectedReservations= this.getSelectedReservations();
      console.log("this are the selected reservations: " + JSON.stringify(selectedReservations));
      let movingReservations = {
        toRoom: 0,
        selectedReservations,
      }
      roomService.deleteReservations(movingReservations).then((e) => {
        console.log("deleted reservations");
        this.$router.go(0);
        this.reCreate();
      }).catch((e) => {
        console.log("Error occured " + JSON.stringify(e))
      })
      this.$root.$emit('bv::hide::modal', "reservationInfo");
    },
    getSelectedReservations(): number[] {
      let result = [] as number[];
      for (let room of this.items) {
        console.log("testing room " + room._key + "=" + this.startReservation.room);
        if (room._key==this.startReservation.room) {
          console.log("found room");
          for (let i= 1; i<=30; i++) {
            let obj= room['day' + i];
            if (this.startReservation.origDate!=null && this.endReservation.date!=null) {
              if (this.startReservation.origDate<=obj.date && this.endReservation.date>=obj.date) {
                result.push(parseInt(obj.key));
              }
            }
          }
          break;
        }
      }
      return result;
    },
    printDate(datStr: string) {
      if (datStr==undefined) return "";
      return datStr.substring(8, 10) + "." + datStr.substring(5, 7) + ". " + datStr.substring(0, 4);
    },
    freeRooms(startDate: string, endDate: string) {
      let result= [] as any[];
      for (let room of this.items) {
        let found= false;
        for (let i= 1; i<=30; i++) {
          let obj= room['day' + i];
          if (startDate<=obj.date && endDate>=obj.date) {
            // console.log("testing room " + room.name + ": " + startDate + "<=" + obj.date + "<=" + endDate + "; " + room.clientKey);
            // check clientkey:
            if (obj.clientKey!=null) {
              console.log("not free:");
              found = true;
            }
          }
        }
        if (!found) {
          result.push(room);
        }
      }
      return result;
    },
    printRoom(id: number) {
      for (let room of this.items) {
        if (room._key == id) {
          return room.name;
        }
      }
      return "-";
    },
    bookFromCustomerDialog(customerItem: Client) {
      console.log("selected customer: " + JSON.stringify(customerItem));
      console.log("mixing with reservation: " + JSON.stringify(this.preselectedReservation));
      let data= {
            "_to": settings.dbschema+"_Room/"+this.preselectedReservation.room,
            "_from": settings.dbschema+"_Client/"+customerItem._key,
            "resDate": this.preselectedReservation.date,
            "createdAt": new Date(),
            "confirmSent": new Date(),
        }
      roomService.insertReservation(data).then(() => {
        this.$router.go(0);
      });
      this.$root.$emit('bv::hide::modal', "chooseCustomer")
    },
    showAddRoom(): void {
      this.selectedRoomElem= {} as RoomElement;
      this.$root.$emit('bv::show::modal', "addRoomDialog")
    },
    loadUnconfirmed() {
      this.hasUnconfirmed = false;
      roomService.getUnconfirmed()
        .then((unconfirm: never[]) => {
          if (unconfirm.length>0) {
            this.hasUnconfirmed = true;
          }
        });
    },
    confirmReservations():void {
      roomService.confirmReservations().then(() => {
        this.loadUnconfirmed();
      });
    },
    updateCustomerList(): void {
      clientService.findByName(this.searchString)
        .then(
          clients => {
            console.log("received clients for table " );
            this.clients= clients;
          },
          error => {
            alert("error occured " + error);

          }
        )

    },
    editFloor(room: RoomElement): void {
      console.log("received room " +  room._key)
      roomService.getRoom(room._key).then((result: IRoom) => {
        console.log("received room details: " + JSON.stringify(result));
        this.selectedRoomElem = result;
        this.newRoom.floor = ''+result.floor;
        this.newRoom.number = result.number;
        this.newRoom.name = result.name;
        this.newRoom.priceWorkdayWork = result.priceWorkdayWork;
        this.newRoom.priceWorkdayNight = result.priceWorkdayNight;
        this.newRoom.priceWeekendWork = result.priceWeekendWork;
        this.newRoom.priceWeekendNight = result.priceWeekendNight;
        this.$root.$emit('bv::show::modal', "addRoomDialog")
      });
    },
    loadFloors() {
      return roomService.getFloors().then((result: Floor[]) => {
        this.floors= [];
        result.forEach(floor => {
          this.floors.push({ value: floor.floorNumber, text: floor.floorName})
        });
      });
    },
    floorName(nr: string): string {
      let result= "Stockwerk " + nr;
      this.floors.forEach(floor => {
        if (nr==floor.value) {
          result= floor.text;
        }  
      });
      return result;
    },
    backgroundColor(floor: string, item: any): string {
      if (item!=null) {
        let dateObj= new Date(item);
        if (dateObj.getDay()==6  || dateObj.getDay()==0) {
          return "#eecccc";
        }
        let found= "";
        this.holidays.forEach(holyDay => {
          // console.log("comparing " + holyDay.key + " with " + item.date);
          let itemStr=new String(item.date).substring(0,10);
          if (itemStr==holyDay.date) {
            found= "#E7E5AB";
          }
        });
        if (found!="") return found;
      }
      if (floor=="0") {
        return "#CAE57B";
      } else if (floor=="1") {
        return "#A5FFF4";
      } else if (floor=="2") {
        return "#FA8DE5";
      } else if (floor=="3") {
        return "#CAE57B";
      } else {
        return "#CAE57B";
      }
    },
    isWeekEnd(dateStr: string): boolean {
      let parts= dateStr.split("|");
      if (parts.length==3) {
        return parts[0]=="SO" || parts[0]=="SA";
      }
      return false;
    },
    setWaitingList():void {
      this.selectedReservation.waitingList = this.reservationOnWaitingList?"y":"n";
    },
    addRoom(): void {
      console.log("adding room with data:" + JSON.stringify(this.newRoom));
      roomService.addRoom(this.newRoom).then(result => {
        console.log("received result " + JSON.stringify(result))
        this.$router.go(0);
      });
    },
    changeRoom(): void {
      const data = {
        _key: this.selectedRoomElem._key,
        name: this.newRoom.name,
        floor: this.newRoom.floor,
        number: this.newRoom.number,
        priceWorkdayWork: this.newRoom.priceWorkdayWork,
        priceWorkdayNight: this.newRoom.priceWorkdayNight,
        priceWeekendWork: this.newRoom.priceWeekendWork,
        priceWeekendNight: this.newRoom.priceWeekendNight,
      }
      console.log("changing room with data:" + JSON.stringify(data));
      roomService.changeRoom(data).then(result => {
        console.log("received result " + JSON.stringify(result))
        this.$router.go(0);
      });
    },
    isSunday(dat: any): boolean {
      let selDat= new Date(dat.date);
      return (selDat.getDay()==0)
    },
    deleteRoom(): void {
      const data = {
        _key: this.selectedRoomElem._key,
        name: this.newRoom.name,
        floor: this.newRoom.floor,
        number: this.newRoom.number
      }
      console.log("deleting room with data:" + JSON.stringify(data));
      roomService.deleteRoom(data).then(result => {
        console.log("received result " + JSON.stringify(result))
        this.$router.go(0);
      });
    },
    updateReservationRemark(): void {
      console.log("updating remark " + JSON.stringify(this.selectedReservation));
      let data= {
        "_key": this.selectedReservation.key,
        "_to": settings.dbschema+"_Room/"+this.selectedReservation.room,
        "_from": settings.dbschema+"_Client/"+this.selectedReservation.clientKey,
        "resDate": this.selectedReservation.date,
        "remark": this.selectedReservation.remark,
        "confirmSent": this.selectedReservation.date,
        "waitingList": this.selectedReservation.waitingList,
      }
      roomService.replaceReservation(data).then(result => {
        this.selectedReservation= result;
        this.$root.$emit('bv::hide::modal', "reservationInfo");
      });

    },
    deleteReservation():void {
      console.log("deleting reservation " + JSON.stringify(this.selectedReservation));
      
      roomService.deleteReservation(this.selectedReservation).then(result => {
        console.log("result recieved for delete " + JSON.stringify(result));
        this.$router.go(0);
        this.reCreate();
      });

    },
    resetModal():void {
      console.log("reset dialog")
    },
    reCreate():void {
      this.loadFloors();
      console.log("Creating planning vue");
      if (this.$route.query.startDay) {
        let millis= Number.parseInt(""+this.$route.query.startDay);
        this.today= new Date(millis);
      } else {
        this.today= new Date();
      }
      this.setStartDay(this.today);
      this.loadUnconfirmed();
    },
    startChange(days: number): void {
      this.today= new Date(this.today.getTime()+days*24*60*60*1000);
      // router.replace({path: "planning"});
      this.$router.replace( {path: "planning", query: {startDay: ""+this.today.getTime() }});
      this.$router.go(0);
    },
    goToday(): void {
      this.today= new Date();
      // router.replace({path: "planning"});
      this.$router.replace( {path: "planning", query: {startDay: ""+this.today.getTime() }});
      this.$router.go(0);
    },
    setStartDay(today: Date): void {
      this.buildFieldList(today);
      roomService.getAllReservations(today, 30).then(rooms => {
        // console.log("received room list " + JSON.stringify(rooms));
        this.items= rooms;
      });
    },
    loadClientImage():void  {
      fileService.getFromClient(""+this.selectedReservation.clientKey, "portrait").then(response => {
          if (response.data!="") {
              console.log("received image data");
              this.imageSource= response.data;
          }
      });
    },
      buildFieldList(today: Date):void {
        this.fields= this.fieldsStart;
        for (let i=1; i<=35; i++) {
            let day= new Date(today.getTime()+(i-1)*1000*24*60*60);
            let date= day.getDate();
            let weekday= settings.weekdays[day.getDay()];
            let labelStr= ""+weekday+"|"+date+"|"+day.toDateString();
            this.fields.push({key: "day"+i, label: labelStr, stickyColumn: false });
        }
        this.nameMonth1= settings.months[today.getMonth()];
        let found1= false;
        for (let i=1; i<=35 && !found1; i++) {
          let day= new Date(today.getTime()+1000*24*60*60*i);
          if (settings.months[day.getMonth()] != this.nameMonth1) {
            this.numMonth1=i;
            this.nameMonth2= settings.months[day.getMonth()];
            found1= true;
            let found2= false;
            for (let j=1; j<=35 && !found2; j++) {
              let day= new Date(today.getTime()+1000*24*60*60*(i+j));
              if (settings.months[day.getMonth()] != this.nameMonth2) {
                this.numMonth2= j;
                this.nameMonth3= settings.months[day.getMonth()];
                this.numMonth3= 35-(i+j);
                found2= true;
              }
            }
          }
        }
      },
      startDrag(event: DragEvent, item: Record<string, unknown>):void {
        console.log("drag started with client " + item._key);
        if (event.dataTransfer!=null) {
          event.dataTransfer.dropEffect= 'copy';
          event.dataTransfer.effectAllowed = 'copy';
          console.log(JSON.stringify(item));
          event.dataTransfer.setData("client", JSON.stringify(item));
        }
      }
  },
  components: {
    DropableCell,
  }
});

import { settings } from "./variables";

export const util = {
    statusLabel,
    statusColor,
    apiUrl,
}

function statusLabel(key: string): string {
    for (const stat of settings.statusList) {
        if (stat.value==key) {
            return stat.text;
        }
    }

    return "status: nicht gefunden";
}
function statusColor(key: string): string {
    switch (key) {
        case 'cancelled': return "#000000";
        case 'new': return "brown";
        case 'active': return "darkgreen";
        case 'inactive': return "orange";
        default: return "darkblue";
    }
}
function apiUrl(): string {
    let result = settings.baseHost + localStorage.getItem("database") + settings.baseAppName;
    if (settings.baseApi!=null) {
        result= settings.baseApi;
    }
    return result;
}
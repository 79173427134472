<template>
    <div class="container-fluid">
      <div class="row">
        <h2>Kundinnen</h2>
      </div>

      <b-row>
        <b col="col-2">
          <b-button size="sm" @click="addClient($event.target)" class="mr-1">
            <b-icon icon="plus" />Neue KundIn anlegen
          </b-button>          
        </b>
        <b-col lg="4" class="my-1">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filter On"
            description="Leave all unchecked to filter on all data"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <b-form-checkbox v-for="field in filterColumns" v-bind:key="field.key" :value="field.key">{{field.label}}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
        
      </b-row>


      <div class="row" >
        <b-table :fields="fields" :items="items" 
          :per-page="perPage"
          :current-page="currentPage"
          @filtered="onFiltered"
          :filter="filter"
          :filter-included-fields="filterOn"
        :tbody-transition-props="transProps" style="min-width: 1650px" striped small primary-key="a">
          <template #cell(firstName)="row">
            <div style="overflow: hidden; white-space: nowrap; text-align: left;" :title="row.item.firstName">{{row.item.firstName}}</div>
          </template>
          <template #cell(lastName)="row">
            <div style="overflow: hidden; white-space: nowrap; text-align: left;" :title="row.item.lastName">{{row.item.lastName}}</div>
          </template>
          <template #cell(artistName)="row">
            <div style="overflow: hidden; white-space: nowrap;  text-align: left;" :title="row.item.artistName">{{row.item.artistName}}</div>
          </template>
          <template #cell(status)="row" >
            <b-container @click="row.toggleDetails">
            <span :style="'background-color: ' + statusColor(row.value) + '; color: white;'">{{ statusLabel(row.value) }}</span>
            </b-container>
          </template>
          <template #cell(fullname)="row">
            <b-container v-bind:style="'background-color: '+row.item.clientColor+' ; color: white; font-weight: bolder;'">
            {{ row.item.firstName.charAt(0) }}{{ row.item.lastName.charAt(0) }}
            </b-container>
          </template>
          <template #cell(birthDay)="row">
            <date-view :value="row.item.birthDay" />
          </template>

          <template #cell(actions)="row">
            <div class="row" style="width: 120px;">
            <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1" title="Datenblatt">
              <b-icon icon="vector-pen"></b-icon>
            </b-button>
            <b-button size="sm" @click="openMeldezette(row.item, true)" class="mr-1" title="Meldezettel ausfüllbar">
              <b-icon icon="file-richtext" />
            </b-button>
            <b-button size="sm" @click="openInvoices(row.item)" class="mr-1" title="Rechnungen">
              <b-icon icon="cash-stack" />
            </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <b col="2">
        <b-button size="sm" @click="addClient($event.target)" class="mr-1">
          <b-icon icon="plus" /> Neue KundIn anlegen
        </b-button>
        </b>
        <b col="10">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b>
      </b-row>
    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" @hide="resetInfoModal" size="lg" style="max-width: 1525px; width: 1500px;" :ok-only="true">
      <edit-client :clientKey="infoModal.data._key" :isNew="infoModal.isNew" />
    </b-modal> 
  </div>
</template>
 
<script>
import { clientService } from '../_services'; 
import EditClient from '../components/EditClient'
import {Vue } from 'vue-property-decorator';
import DateView from '@/components/DateView.vue';
import { settings } from '@/variables';
import { util } from '@/functions';
import EventBus  from '@/variables';

export default Vue.extend({
  name: "App",
  data() {
    return {
      transProps: {
        name: 'flip-list'
      },
      fields: [
        { key: "lastName", label: "Nachname", sortable: "true", },
        { key: "firstName", label: "Vorname", sortable: "true", },
        { key: "artistName", label: "Künstlername", sortable: "true"},
        { key: "status", label: "Status" },
        { key: "birthDay", label: "Geburtstag", filter: "true", sortable: "true" },
        { key: "placeOfBirth", label: "Geb.Ort", filter: "true" },
        { key: "address", label: "Adresse", filter: "true" },
        { key: "zipcode", label: "PLZ", filter: "true" },
        { key: "city", label: "Ort", filter: "true" },
        { key: "contactPhone", label: "TelefonNr", filter: "true" },
        { key: "actions", label: "Aktion"}
      ],
      statusList: settings.statusList,
      filterColumns: [
        {key: 'lastName', label: 'Nachname'},
        {key: 'firstName', label: 'Vorname'},
        {key: 'artistName', label: 'Künstlername'},
        {key: 'city', label: 'Stadt'}
      ],
      filteredItems: [],
      items: [
      ],
      infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
          data: {},
          isNew: false
      },
      currentPage: 1,
      rows: 0,
      perPage: 20,
      filter: null,
      filterOn: [],
      totalRows: 1,
      eventBus: EventBus,
    };
  },
  created() {
    this.reloadCustomers();
    this.eventBus.$on("reloadCustomers", (filter) => {
      this.$bvModal.hide("info-modal")
      this.reloadCustomers();
      if (filter!=null) {
        this.filter= filter;
      }
    })
  },
  beforeDestroy() {
    this.eventBus.$off("reloadCustomers");
  },
  methods: {
      statusLabel(txt) {
        return util.statusLabel(txt);
      },
      statusColor(txt) {
        return util.statusColor(txt);
      },
      reloadCustomers() {
        clientService.getAll()
          .then(
            clients => {
              console.log("received clients for table");
              this.items= clients;
              this.totalRows = clients.length;
              this.rows = clients.length;
            },
            error => {
              alert("error occured " + error);

            }
          )
      },
      info(item, index, button) {
        this.infoModal.title = `KundIn bearbeiten`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        this.infoModal.data= item
        this.infoModal.isNew = false;
      },
      openMeldezette(item, editable) {
        if (editable) {
          window.open(settings.printApi + "/Meldezettel?id="+item._key + "&db=" + localStorage.getItem("database"), "_blank").focus();
        } else {
          window.open(settings.printApi + "/Meldezettel?id="+item._key + "&db=" + localStorage.getItem("database")+"&flattened=1", "_blank").focus();
        }
      },
      openInvoices(item) {
        window.location.href = "/invoices?customer="+item._key + "&ln=" + item.lastName + "&fn=" + item.firstName; 
      },
      addClient(button) {
        this.infoModal.title = `KundIn hinzufügen`
        this.infoModal.content = ""
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        this.infoModal.data= {};
        this.infoModal.isNew= true;
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
        this.reloadCustomers();
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.rows = filteredItems.length
        this.currentPage = 1
    }
  },
  components: {
    EditClient,
    DateView,
  }
});

</script>
<style >
.modal-lg, .modal-xl {
    max-width: 1300px;
}
</style>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings } from '@/variables';
import { util } from '@/functions';
import { authToken, handleAuthorization } from '@/_helpers/auth-header';

const header= {headers: {Authorization: `bearer ${authToken()}`}, method: "GET"};


export const clientService = {
    getAll,
    getById,
    updateClient,
    insertClient,
    findByName,
    deleteClient,
};

function getAll() {
    const url= `${util.apiUrl()}/client/sorted`;
    return fetch(url, header).then(handleAuthorization);
}


async function getById(key: string) {
    const url= `${util.apiUrl()}/client/${key}`;
    console.log(url, JSON.stringify(header));
    const data= await fetch(url, header).then(handleAuthorization);
    console.log(JSON.stringify(data));
    return data; 
}

function findByName(name: string) {
    const url= `${util.apiUrl()}/client/name/${name}`;
    console.log(url, JSON.stringify(header));
    const data= fetch(url, header).then(handleAuthorization);
    console.log(JSON.stringify(data));
    return data; 
}
function updateClient(key: string, data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/client/${key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'PUT',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleAuthorization).then(response => handleUpdateClient(response));

}

function deleteClient(key: string) {
    const url= `${util.apiUrl()}/client/${key}`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'DELETE',
        headers: headerObj
    };
    console.log(url);
    return fetch(url, requestOptions).then(handleAuthorization).then(response => handleUpdateClient(response));

}

function insertClient(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/client/`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleAuthorization).then(response => handleUpdateClient(response));

}

function handleUpdateClient(response: any) {
    console.log("handleUpdateClient");
    console.log("received data " + JSON.stringify(response));
    return response;
}
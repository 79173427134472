
















































































































































































import { Vue } from 'vue-property-decorator';
import { roomService, invoiceService, clientService} from '../_services';
import { settings } from '@/variables';
import EventBus from '@/variables'
import { BootstrapVueIcons } from 'bootstrap-vue'
import EditInvoice  from '../components/CreateInvoice.vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import DateView from '@/components/DateView.vue';
import {IRoomList, IInvoiceList, Invoice, RoomElement, CheckIn, Reservation, Client, IRoom } from '@/types';

Vue.use(BootstrapVueIcons);


export default Vue.extend({
  name: "App",
  data() {
    return {
      vorschlagNr: 0,
      clients: [],
      selectedReservation: {},
      rooms: {} as IRoomList,
      selectedCheckIn: {},
      today: new Date(),
      monthNames: settings.months,
      eventHub: EventBus,
      selectedRoom: "",
      numberOfDays: 10,
      daysBeforeNow: 3,
      itemFields: [
        { key: "roomNumber", label: "ZimmerNr | Zeile" },
        { key: "roomKey", label: "ZimmerKey | Text" },
        { key: "usageDate", label: "Datum | Anzahl, Einzelpreis"}, 
        { key: "itemNetto", label: "Netto"},
        { key: "itemPrice", label: "Preis" }, 
        { key: "itemPercent", label: "UST%"  },
      ],
      fields: [
        { key: "invoiceDate", label: "Datum", filter: "true" },
        { key: "invoiceNumber", label: "RNr", filter: "true"}, 
        { key: "lastName", label: "Nachname", sortable: "true", },
        { key: "firstName", label: "Vorname", sortable: "true", },
        { key: "artistName", label: "Alias", sortable: "true", },
        { key: "netSum", label: "Summe Netto", filter: "true" },
        { key: "taxSum", label: "UST", filter: "true" },
        { key: "fullPrice", label: "Gesamtpreis", filter: "true" },
        { key: "actions", label: "" }
      ],
      invoices: [] as Invoice[],
      printInvoices: [] as Invoice[],
      searchInvoices: [] as Invoice[],
      checkInData: [],
      newInvoices: {} as IInvoiceList,
      suggestedInvoices: [] as Invoice[],
      searchDateFrom: "",
      searchDateTo: "",
      customer: '',
      customerFN: '',
      customerLN: '',
      infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
          data: {},
          isNew: false
        }
    };
  },
  created():void {
    this.loadRooms();
    this.loadInvoices();
    this.loadCheckIns();
    this.reCreate();
    let dat1= new Date();
    dat1.setDate(dat1.getDate()-7);
    this.searchDateFrom = dat1.toISOString().substring(0,10);
    let dat2= new Date();
    dat2.setDate(dat2.getDate()+1);
    this.searchDateTo = dat2.toISOString().substring(0,10);
  },
  mounted():void {
    this.eventHub.$on('reservationInfo', (reservation: Reservation) => {
      (this as any).selectedReservation= reservation;
      (this as any).$root.$emit('bv::show::modal', "reservationInfo");
      (this as any).loadClientImage();
      (this as any).items.forEach((roomElem: RoomElement) => {
        if (roomElem._key==reservation.room) {
          this.selectedRoom= roomElem.name;
        }
      });
    });
    this.eventHub.$on("recalcuatePrices", () => {
      this.suggestedInvoices.forEach((invoice: Invoice) => {
        invoice.netSum= 0.0;
        invoice.taxSum= 0.0;
        invoice.fullPrice= 0.0;
        invoice.items.forEach(itm => {
          // console.log("calc netto " + itm.itemPrice + ", percent: " + itm.itemPercent);
          let val100= Number(itm.itemPrice)*100;
          let percnt= Number(100)+Number(itm.itemPercent);
          let netVal= val100 / percnt;
          // console.log("netto: " + netVal + ", val100: " + val100+", percnt: " + percnt);
          itm.itemNetto= Number(netVal).toFixed(2);
          itm.itemPrice= Number(itm.itemPrice);
          invoice.fullPrice += Number(itm.itemPrice);
          invoice.netSum += Number(Number(itm.itemPrice) / ((Number(100)+Number(itm.itemPercent))/100));
          invoice.taxSum += Number((Number(itm.itemPrice) * Number(itm.itemPercent))/((Number(100)+Number(itm.itemPercent))));
        })
        invoice.netSum= Number(Number(invoice.netSum).toFixed(2));
        invoice.taxSum= Number(Number(invoice.taxSum).toFixed(2));
      });
      this.eventHub.$emit("updateTables");
    });
    this.eventHub.$on("addInvoice", (newClient: Client) => {
      let year= new Date().getFullYear();
      this.suggestedInvoices.push({
        customerKey: newClient.customerKey,
        firstName: newClient.firstName,
        lastName: newClient.lastName,
        artistName: newClient.artistName,
        address: newClient.address,
        zipcode: ""+newClient.zipcode,
        city: newClient.city,
        netSum: Number(Number((settings.defaultPrice*100) / (100+settings.defaultTax)).toFixed(2)),
        taxSum: Number(Number((settings.defaultPrice*settings.defaultTax ) / (100+settings.defaultTax)).toFixed(2)),
        fullPrice: settings.defaultPrice,
        invoiceDate: new Date(),
        invoiceYear: year,
        status: 'new',
        invoiceNumber: this.maxInvoiceNumber(year)+1,
        items: [
          {
            roomKey: "Rechnungszeile",
            roomNumber: "Pos 1",
            usageDate: new Date(),
            amount: 1,
            singlePrice: settings.defaultPrice,
            itemPrice: settings.defaultPrice,
            itemPercent: settings.defaultTax,
            itemNetto: Number((settings.defaultPrice*100) / (100+settings.defaultTax)).toFixed(2),
          }
        ]
      });
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    });
    this.eventHub.$on("updateTables", () => {
      this.vorschlagNr++;
    });
    let params = new URLSearchParams(window.location.search);
    let customer = params.get("customer");
    if (customer != null) {
      // load customer
      this.customer = customer;
      this.customerFN = '' + params.get("fn");
      this.customerLN = '' + params.get("ln");
      invoiceService.getInvoicesByCustomer(customer).then(result => {
        console.log("invoices received");
        this.searchInvoices= result;
      });

    }
  },
  methods: {
    recalcItem(itemArr: any) {
      console.log("itemArr" + JSON.stringify(itemArr));
      itemArr.itemPrice = itemArr.amount*itemArr.singlePrice;
      this.reCalc();
    },
    isRoomPosition(roomKey: string) {
      return roomKey.startsWith(settings.dbschema+"_Room");
    },
    reloadAllData() {
      this.invoices = [];
      this.checkInData = [];
      this.suggestedInvoices = [];
      this.newInvoices = {} ;
      this.loadRooms();
      this.loadInvoices();
      this.loadCheckIns();
      this.reCreate();
    },
    loadRooms() {
      roomService.getAll().then(result => {
        console.log("rooms received");
        this.rooms= result;
      });
    },
    loadInvoices() {
      invoiceService.getInvoicesByStatus("new").then(result => {
        console.log("invoices for printing received " + JSON.stringify(result));
        this.printInvoices= result;
      });

      let from = new Date();
      from.setDate(from.getDate()-14);
      let today= new Date();
      today.setDate(today.getDate()+1);
      invoiceService.getInvoicesByDate(from.toJSON().slice(0,10), today.toJSON().slice(0,10)).then(result => {
        console.log("invoices received");
        this.invoices= result;
        this.reCreate();
      });

    },
    searchInvoiceByDate() {
      invoiceService.getInvoicesByDate(this.searchDateFrom, this.searchDateTo).then(result => {
        console.log("invoices received");
        this.searchInvoices= result;
      });
    },
    loadCheckIns() {
      roomService.getTodayCheckIns().then(result => {
        console.log("checkins received");
        this.checkInData= result;
        this.reCreate();
      });
    },
    downloadInvoice(invoiceItem: any, format: number) {
      console.log("downloading invoice nr. " + JSON.stringify(invoiceItem));
      if (invoiceItem!=null && invoiceItem._key!=null) {
        let w = window.open(settings.printApi+"/Invoice?id="+invoiceItem._key + "&format=" + format, "_blank");
        if (w!=null) {
          w.focus();
        }
      }
    },
    addRow(rowkey: string): void {
      console.log("selected row " + rowkey);
      this.suggestedInvoices.forEach((invoice: Invoice) => {
        if (invoice.customerKey==rowkey) {
          console.log("found invoice here " + JSON.stringify(invoice));
          invoice.items.push({
                    roomKey: "Rechnungszeile",
                    roomNumber: "Pos "+(invoice.items.length+1) ,
                    usageDate: new Date(),
                    amount: 1,
                    singlePrice: settings.defaultPrice,
                    itemPrice: settings.defaultPrice,
                    itemPercent: settings.defaultTax,
                   itemNetto: Number((settings.defaultPrice*100) / (100+settings.defaultTax)).toFixed(2),
          });
          let invNetSum = Number(invoice.netSum) + Number((settings.defaultPrice*100) / (100+settings.defaultTax));
          invoice.netSum = Number(Number(invNetSum).toFixed(2));
          let invTaxSum= Number(invoice.taxSum) +  Number((settings.defaultPrice*settings.defaultTax) / (100+settings.defaultTax));
          invoice.taxSum = Number(Number(invTaxSum).toFixed(2));
          invoice.fullPrice += settings.defaultPrice;
        }
      });
    },
    reCreate():void {
      if (this.checkInData.length==0 ) { // || this.invoices.length==0) {
        // console.log("no data loaded, waiting for all data");
        return;
      }
      let today= new Date();
      let invNumber= this.maxInvoiceNumber(today.getFullYear());
      this.checkInData.forEach((checkIn: CheckIn) => {
        let checkDate= new Date(checkIn.checkInDate);
        let lastDate= new Date(); // in case of active checkins
        if (checkIn.checkOutDate !== undefined) {
          lastDate= new Date(checkIn.checkOutDate);
        }
        while (checkDate<=today && checkDate<=lastDate) {
          if (!this.customerHasInvoice(checkIn._from, checkIn._to, checkDate)) {
            let room = this.findRoom(checkIn._to);
            console.log("no invoice, lets bill " +checkIn._key + "dat: " + checkDate + "; " + JSON.stringify(room));
            if (this.newInvoices[checkIn._from as any]!=null) {
              let price= room.priceWorkdayWork!=null?room.priceWorkdayWork:settings.defaultPrice;
              if (this.isWeekend(checkDate)) {
                price = room.priceWeekendWork!=null?room.priceWeekendWork:settings.halfPrice;
              }
              this.newInvoices[checkIn._from].items.push({
                roomKey: checkIn._to,
                roomNumber: room.name + ' Tag',
                usageDate: checkDate,
                itemPrice: price,
                singlePrice: price,
                amount: 1,
                itemPercent: settings.defaultTax
              });
              if (checkIn.overNight=="Y") {
                let price= room.priceWorkdayNight!=null?room.priceWorkdayNight:settings.defaultPrice;
                if (checkDate.getDay()==0 || checkDate.getDay()==6) {
                  price = room.priceWeekendNight!=null?room.priceWeekendNight:settings.halfPrice;
                }
                this.newInvoices[checkIn._from].items.push({
                  roomKey: checkIn._to,
                  roomNumber: room.name + ' Nacht',
                  usageDate: checkDate,
                  itemPrice: price,
                  singlePrice: price,
                  amount: 1,
                  itemPercent: settings.reducedTax,
                });

              }

            } else {
              invNumber++;
              let price= room.priceWorkdayWork!=null?room.priceWorkdayWork:settings.defaultPrice;
              if (checkDate.getDay()==0 || checkDate.getDay()==6) {
                price = room.priceWeekendWork!=null?room.priceWeekendWork:settings.halfPrice;
              }
              this.newInvoices[checkIn._from]= {
                checkInKey: checkIn._key,
                customerKey: checkIn._from,
                invoiceDate: new Date(),
                invoiceYear: new Date().getFullYear(),
                invoiceNumber: invNumber,
                netSum: 0,
                taxSum: 0,
                fullPrice: 0,
                status: 'new',
                items: [
                  {
                    roomKey: checkIn._to,
                    roomNumber: room.name + ' Arbeit',
                    usageDate: checkDate,
                    itemPrice: price,
                    singlePrice: price,
                    amount: 1,
                    itemPercent: settings.defaultTax,
                  }
                ]
              }
              if (checkIn.overNight=="Y") {
                let price= room.priceWorkdayNight!=null?room.priceWorkdayNight:settings.defaultPrice;
                if (checkDate.getDay()==0 || checkDate.getDay()==6) {
                  price = room.priceWeekendNight!=null?room.priceWeekendNight:settings.halfPrice;
                }
                this.newInvoices[checkIn._from].items.push({
                  roomKey: checkIn._to,
                  roomNumber: room.name + ' Nacht',
                  usageDate: checkDate,
                  itemPrice: price,
                  singlePrice: price,
                  amount: 1,
                  itemPercent: settings.reducedTax,
                });

              }

            }
          }
          checkDate= new Date(checkDate.getTime()+1000*24*60*60);
        }
      });
      let tempSuggestions= [];
      for (var key in this.newInvoices) {
        tempSuggestions.push(this.newInvoices[key]);
      }
      tempSuggestions.forEach(invoice => {
        let customerKey= (""+invoice.customerKey).split("/")[1];
        clientService.getById(customerKey).then(customer => {
            invoice.customerKey= customerKey;
            invoice.firstName= customer.firstName;
            invoice.lastName= customer.lastName;
            invoice.artistName = customer.artistName;
            invoice.address= customer.address;
            invoice.zipcode= ""+customer.zipcode;
            invoice.city= customer.city;
          this.suggestedInvoices.push(invoice);
          this.eventHub.$emit("recalcuatePrices", tempSuggestions.length==this.suggestedInvoices.length);
        });
      });
    },
    customerHasInvoice(customerKey: string, roomKey: string, date: Date): boolean {
      if (this.invoices.length==0) {
        return false;
      }
      let result= false;
      this.invoices.forEach((invoice: Invoice) => {
        if (customerKey==(settings.dbschema+'_Client/' + invoice.customerKey)) {
          let isoDate= date.toISOString();
          let invDat: Date = new Date(invoice.invoiceDate);
          let invDatStr= invDat.toISOString();
          if (invDatStr.substring(0,10) >= isoDate.substring(0,10)) {
            console.log("found customer " + invoice.customerKey + "==" + customerKey + " &&  " + invDatStr.substr(0,10) + ">=" + isoDate.substr(0,10) + "!");
            invoice.items.forEach(item => {
              let itmUsgDat= new Date(item.usageDate);
              if (itmUsgDat.toISOString()==isoDate && item.roomKey==roomKey) {
                console.log("found billed item: " + item.usageDate + " with " + isoDate + ", roomKey " + item.roomKey+"?=" + roomKey);
                result= true;
              }
            });
          }
        }
      });
      console.log("returning result " + result)
      return result;
    },
    maxInvoiceNumber(year: number) {
      let maxNumber= 0;
      for (let i in this.invoices) {
        let invoice= this.invoices[i];
        if (invoice.invoiceNumber>maxNumber) {
          maxNumber= invoice.invoiceNumber;
        }
      }
      return maxNumber;
    },
    findRoom(fullkey: string): any {
      console.log("finding room " + fullkey);
      let key= fullkey.split("/")[1];
      for (let rk in this.rooms) {
        let room= this.rooms[rk];
        console.log("check " + room._key);
        if (key==room._key) {
          return room;
        }
      }
      return null;
    },
    isWeekend(dat: Date) {
      // TODO check holidays
      return dat.getDate()==0 || dat.getDate()==6; 
    },
    reCalc() {
      console.log("recalc should start");
      this.eventHub.$emit("recalcuatePrices");
    },
    saveInvoices() {
      console.log("storing invoices");
      let number= 0;
      let total= this.suggestedInvoices.length;
      this.suggestedInvoices.forEach(invoice => {
        for (let i in invoice.items) {
          let inv= invoice.items[i];
          inv.itemPrice= Number(inv.itemPrice);
        }
        delete invoice._showDetails;
        // console.log("storing " + JSON.stringify(invoice));
        invoiceService.storeInvoice(invoice).then(result => {
          number++;
          console.log("Stored Invoice Number " + number + " out of total " + total);
          if (number==total) {
            console.log("max invoice reached, recreating gui")
            this.reloadAllData();
          }
        })
      });
    },
    manualInvoice(button: any) {
        this.infoModal.title = `Individualrechnung anlegen`
        this.infoModal.content = ""
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        this.infoModal.data= {};
        this.infoModal.isNew= true;
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

  },
  components: {
    EditInvoice,
    DateView,
  }
  
});

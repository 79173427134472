/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings } from '@/variables';
import { authHeader } from '@/_helpers/auth-header';


export const userService = {
    login,
    logout,
    getAll,
    getById
};

function login(username: string, password: string):Promise<Record<string, unknown>> {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    const url= settings.loginUrl;
    console.log("Settings: " + JSON.stringify(settings));
    console.log("URL:" + url);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.jwt) {
                user.name= username;
                user.password = password;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));


            }
            return Promise.resolve(user);
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions: HeadersInit = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${settings.loginUrl}/users`, requestOptions).then(handleResponse);
}


function getById(id: string) {
    const requestOptions: HeadersInit = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${settings.loginUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return Promise.resolve(data);
    });
}
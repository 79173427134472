/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings } from '@/variables';
import { util } from '@/functions';
import { authToken, handleAuthorization } from '@/_helpers/auth-header';

const header= {headers: {Authorization: `bearer ${authToken()}`}, method: "GET"};


export const healthdataService = {
    getAll,
    getById,
    insertClientHealthData,
    getFromClient,
    getDoctorsList,
};

function getAll() {
    const url= `${util.apiUrl()}/healthdata`;
    return fetch(url, header).then(handleAuthorization);
}

function getDoctorsList() {
    const url= `${util.apiUrl()}/clienthealthdata/arztliste`;
    return fetch(url, header).then(handleAuthorization);
}


function getById(key: string) {
    const url= `${util.apiUrl()}/healthdata/${key}`;
    console.log(url, JSON.stringify(header));
    const data= fetch(url, header).then(handleAuthorization);
    console.log(JSON.stringify(data));
    return data; 
}

function getFromClient(key: string) {
    const url= `${util.apiUrl()}/healthdata/fromClient/${key}`;
    console.log(url, JSON.stringify(header));
    const data= fetch(url, header).then(handleAuthorization);
    console.log(JSON.stringify(data));
    return data; 
}

function insertClientHealthData(data: Record<string, unknown>) {
    const url= `${util.apiUrl()}/clienthealthdata/`;
    const headerObj= new Headers({'Content-Type': 'application/json'});
    headerObj.append('Authorization', `bearer ${authToken()}`);
    const requestOptions = {
        'method': 'POST',
        headers: headerObj,
        body: JSON.stringify(data)
    };
    console.log(url+", options: " + JSON.stringify(requestOptions));
    return fetch(url, requestOptions).then(handleUpdateClientHealth);
}

function handleUpdateClientHealth(response: any) {
    return response.text().then((text: string) => {
        console.debug("handling response from server");
        const data = text && JSON.parse(text);
        return data;
    });
}
